
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

const Register = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState(false)
  
  const handlePrivacyPolicy = () => {
    setPrivacyPolicy(!privacyPolicy)
  }

  return (
    <>
      <Col lg="6" md="8">
        <Row className="mt-3">
          <Col className="text-center pb-3">
            <img
              src={require("../../assets/img/brand/atidot-logo-white.png")}
              alt="Atidot"
            />
          </Col>
        </Row>
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Name" type="text" />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                  />
                </InputGroup>
              </FormGroup>
              <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                      checked={privacyPolicy}
                      onChange={handlePrivacyPolicy}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span className="text-muted">
                        I agree with the{" "}
                        <a href="https://www.atidot.com/privacy-notice/" target="_blank" rel="noreferrer">
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>
              <div className="text-center">
                <Button className="mt-4" color="primary" type="button" disabled={!privacyPolicy}>
                  Create account
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        
        <Row className="mt-3">
          <Col className="text-center" xs="12">
            <Link
              className="text-light"
              to="../login"
            >
              <small>Login</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Register;
