import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

const Login = () => {
  const navigate = useNavigate()
  const [rememberPassword, setRememberPassword] = useState(true)
  const [data, setData] = useState({
    login: "",
    password: ""
  })
  
  const handleRememberMe = () => {
    setRememberPassword(!rememberPassword)
  }

  const handleChangeData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  const handleLogin = () => {
    if (rememberPassword) {
      localStorage.setItem("atidot-back-office-creds", JSON.stringify(data))
    }
    localStorage.setItem("token", true)
    navigate("/admin/index")
  }

  useEffect(() => {
    const credentials = localStorage.getItem("atidot-back-office-creds")
    if (credentials) {
      setData(JSON.parse(credentials))
    }
  }, [])

  return (
    <>
      <Col lg="5" md="7">
        <Row className="mt-3">
          <Col className="text-center pb-3">
            <img
              src={require("../../assets/img/brand/atidot-logo-white.png")}
              alt="Atidot"
            />
          </Col>
        </Row>
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            {/* <div className="text-center text-muted mb-4">
              <small>Or sign in with credentials</small>
            </div> */}
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    name="login"
                    autoComplete="new-email"
                    value={data.login}
                    onChange={handleChangeData}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    name="password"
                    autoComplete="new-password"
                    value={data.password}
                    onChange={handleChangeData}
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                  checked={rememberPassword}
                  onChange={handleRememberMe}
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div>
              <div className="text-center">
                <Button className="mt-4" color="primary" type="button" onClick={handleLogin} disabled={!(data.login && data.password)}>
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <Link
              className="text-light"
              to="../register"
            >
              <small>Create new account</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
